<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6" :class="order">
          <img src="../../assets/images/macbook.png" alt="about Img" class="img-fluid about-img" />
        </div>

        <div class="col-lg-6">
          <div class="about-txt">
            <div class="title-box">
              <h2 class="text-center">{{$t("Frontend development")}}</h2>
              <div class="border"></div>
            </div>

            <p>{{$t("For frontend development we have modern, powerful, stable and scalable tools, through which we seek to provide communication solutions with the least possible friction between the client and the application.")}}</p>
            <p>{{$t("We mainly use React and Vue as JS frameworks, as well as experts in the field that are creating and embodying customized solutions for each project.")}}</p>
            <p>{{$t("For projects that do not need a complex Backend service we offer integration with Firebase, through which we have databases, authentication, mail delivery functions among others.")}}</p>
            <div class="row align-items-center">
              <div class="col-4">
                <img src="../../assets/images/technologies/react.png" alt="react" class="img-icon" />
              </div>
              <div class="col-4">
                <img
                  src="../../assets/images/technologies/firebase.png"
                  alt="firebase"
                  class="img-icon"
                />
              </div>
              <div class="col-4">
                <img src="../../assets/images/technologies/vue.png" alt="vue" class="img-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FrontEnd",
  props: {
    order: {
      default: "",
      type: String
    }
  }
};
</script>

